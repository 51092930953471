import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Pagination from "react-js-pagination";
import { unwrapResult } from "@reduxjs/toolkit";
import { getContactSubmissions } from "../../store/slices/packages";
import "./package.template.less";

const ContactSubmissions = () => {
  const user = localStorage.getItem("SBCA__user");
  const [number, SetNumber] = useState(1);
  const dispatch = useDispatch();
  const submissions = useSelector((state) => state?.packages?.data?.submissions);
  const [data, setData] = useState(submissions);
  const list = data?.slice((number - 1) * 10, (number - 1) * 10 + 10);
  const total = data?.length;
  const pageTotal = (number*10) > total ? total : number*10;
  const pageStartCount = (pageTotal < 1) ? 0 : (total === pageTotal) ? total : pageTotal-9;
  const [sid, setSid] = useState(false);
  const [created, setCreated] = useState(false);
  const [name, setName] = useState(true);
  const [ip, setIp] = useState(false);
  const [currentTab, setCurrentTab] = useState("sid");
  const id = JSON?.parse(user).uid;
  const username = JSON?.parse(user).name;
  const fullname = JSON?.parse(user).firstName + ' ' + JSON?.parse(user).lastName;
  useEffect(() => {
    (async () => {
      try {
        const resultAction = await dispatch(getContactSubmissions(id));
        const originalPromiseResult = unwrapResult(resultAction);
        setData(originalPromiseResult);
      } catch (rejectedValueOrSerializedError) {
        // handle error here
      }
    })();
  }, [id, dispatch]);
  const handlePageChange = (e) => {
    SetNumber(e);
  };
  const handlefilter = (e) => {
    e.preventDefault();
    setCurrentTab(e.target.value);
    if (e.target.value === "sid") {
      setSid(!sid);
      if (sid === false) {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a.nid > b.nid ? -1 : 1
        );
        setData(x);
      } else {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a.nid < b.nid ? -1 : 1
        );
        setData(x);
      }
    }
    if (e.target.value === "created") {
      setCreated(!created);
      if (created === false) {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a?.created > b?.created
            ? -1
            : 1
        );
        setData(x);
      } else {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a?.created < b?.created
            ? -1
            : 1
        );
        setData(x);
      }
    }
    console.log(e.target.name);
    if (e.target.value === "name" || e.target.id === "name") {
      setName(!name);
      if (name === false) {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a?.field_first_name[0]?.value > b?.field_first_name[0]?.value ? -1 : 1
        );
        setData(x);
      } else {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a?.field_first_name[0]?.value < b?.field_first_name[0]?.value ? -1 : 1
        );
        setData(x);
      }
    }
    if (e.target.value === "ip" || e.target.name === "ip") {
      setIp(!ip);
      if (ip === false) {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a?.field_ip_address[0]?.value > b?.field_ip_address[0]?.value ? -1 : 1
        );
        setData(x);
      } else {
        let y = [...data];
        let x = y?.sort((a, b) =>
          a?.field_ip_address[0]?.value < b?.field_ip_address[0]?.value ? -1 : 1
        );
        setData(x);
      }
    }
  };

  return (
    <>
      <h1 className="page-header">Submissions for <i>{username}</i></h1>
      <div className="table-responsive submissions-table">
        <span>Showing {pageStartCount} - {pageTotal} of {total}</span>
        <table className="PreviewTable sortable-table table-responsive">
          <thead>
            <tr>
              <th
                className={
                  currentTab
                    ? currentTab === "sid"
                      ? "active"
                      : ""
                    : ""
                }
              >
                <button
                  onClick={handlefilter}
                  className={
                    sid === true
                      ? "fa fa-chevron-up tooltip-new "
                      : "fa fa-chevron-down tooltip-new "
                  }
                  value={"sid"}
                >
                  #
                  <span className="tooltiptext">
                    {sid === true ? "sort ascending" : "sort descending"}
                  </span>
                </button>
              </th>
              <th
                className={
                  currentTab
                    ? currentTab === "created"
                      ? "active"
                      : ""
                    : ""
                }
              >
                <button
                  onClick={handlefilter}
                  className={
                    created === true
                      ? "fa fa-chevron-up tooltip-new "
                      : "fa fa-chevron-down tooltip-new "
                  }
                  value={"created"}
                >
                  Submitted
                  <span className="tooltiptext">
                    {created === true ? "sort ascending" : "sort descending"}
                  </span>
                </button>
              </th>
              <th
                className={
                  currentTab
                    ? currentTab === "name"
                      ? "active"
                      : ""
                    : ""
                }
              >
                <button
                  onClick={handlefilter}
                  className={
                    name === true
                      ? "fa fa-chevron-up tooltip-new "
                      : "fa fa-chevron-down tooltip-new "
                  }
                  value={"name"}
                >
                  User
                  <span className="tooltiptext">
                    {name === true ? "sort ascending" : "sort descending"}
                  </span>
                </button>
              </th>
              <th
                className={
                  currentTab
                    ? currentTab === "ip"
                      ? "active"
                      : ""
                    : ""
                }
              >
                <button
                  onClick={handlefilter}
                  className={
                    ip === true
                      ? "fa fa-chevron-up tooltip-new "
                      : "fa fa-chevron-down tooltip-new "
                  }
                  value={"ip"}
                >
                  IP Address
                  <span className="tooltiptext">
                    {ip === true ? "sort ascending" : "sort descending"}
                  </span>
                </button>
              </th>
              <th>Operations</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((submission, id) => {
              return (
                <>
                  <tr id={id} key={id}>
                    <td className={
                      currentTab
                        ? currentTab === "sid"
                          ? "active"
                          : ""
                        : ""
                    }>
                      <NavLink to={{pathname:`/submission/${submission.nid}`, state:submission}}>
                        {submission?.nid}
                      </NavLink>
                    </td>
                    <td className={
                      currentTab
                        ? currentTab === "created"
                          ? "active"
                          : ""
                        : ""
                    }>{submission?.created}</td>
                    <td className={
                      currentTab
                        ? currentTab === "name"
                          ? "active"
                          : ""
                        : ""
                    }>{fullname}</td>
                    <td className={
                      currentTab
                        ? currentTab === "ip"
                          ? "active"
                          : ""
                        : ""
                    }>{submission?.field_ip_address[0]?.value}</td>
                    <td>
                      <NavLink to={{ pathname: `/submission/${submission?.nid}` }}>view</NavLink>
                      <NavLink to={{ pathname: `/submission/${id}/edit` }}>edit</NavLink>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {submissions.length > 10 &&
        <div className="text-center">
          <Pagination
            totalItemsCount={total}
            onChange={handlePageChange}
            activePage={number}
            itemsCountPerPage={10}
            pageRangeDisplayed={10}
          />
        </div>
      }
    </>
  );
}

export default ContactSubmissions;