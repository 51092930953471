import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { getSubmission } from "../../store/slices/packages";
import "./package.template.less";

const Submission = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const submission = useSelector((state) => state?.packages?.data?.submission);
  useEffect(() => {
    setTimeout(function() {
      dispatch(getSubmission(id));
    }, 500);
  }, [id,dispatch]);

  console.log(submission);

  return (
    <>
      <h1 className="page-header">Submission for #{submission.nid}</h1>
      <ul className="nav-tabs">
        <li className="active">
          <NavLink to={{ pathname: `/submission/${submission.nid}` }}>View</NavLink>
        </li>
        <li>
          <NavLink to={{ pathname: `/submission/${submission.nid}/edit` }}>Edit</NavLink>
        </li>
      </ul>
      <div className="submission--field">
        <label>First&nbsp;</label>
        <span>{submission?.field_first_name && submission?.field_first_name[0]?.value}</span>
      </div>
      <div className="submission--field">
        <label>Last&nbsp;</label>
        <span>{submission?.field_last_name && submission?.field_last_name[0]?.value}</span>
      </div>
      <div className="submission--field">
        <label>Last&nbsp;</label>
        <span>{submission?.field_e_mail && submission?.field_e_mail[0]?.value}</span>
      </div>
      <div className="submission--field">
        <label>Comments&nbsp;</label>
        <span dangerouslySetInnerHTML={{ __html: submission?.body }} />
      </div>
    </>
  );
}

export default Submission;