import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";
import classNames from "classnames";
import { addContact } from "../../store/slices/packages";
import { getContactSubmissions } from "../../store/slices/packages";
import "./package.template.less";

const ContactForm = () => {
  const dispatch = useDispatch();
  const [showForm, setshowForm] = React.useState(false);
  const [error, SetError] = useState({});
  const user = localStorage.getItem("SBCA__user");
  const userData = JSON?.parse(user);
  const submissions = useSelector((state) => state?.packages?.data?.submissions);
  const totalSubmissions = submissions?.length;
  const [data, SetData] = useState({
    email: [{ value: "" }],
    firstname: [{ value: "" }],
    lastname: [{ value: "" }],
    comments: [{ value: "" }],
    ip_address: [{ value: "" }],
    author: [{ value: "" }]
  });
  const [form, setForm] = useState(false);
  const [ip, setIP] = useState("");

  const getIp = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  }

  useEffect(() => {
    (async () => {
      try {
        dispatch(addContact({ val: data }));

        if (ip === "") {
          getIp();
        }

        if (userData.uid !== null && userData.uid !== '') {
          let values = {
            email: [{ value: userData?.email }],
            firstname: [{ value: userData?.firstName }],
            lastname: [{ value: userData?.lastName }],
            ip_address: [{ value: ip }],
            author: [{ value: userData?.uid }]
          }
          SetData({ ...data, ...values });

          // Make sure that this is only run once
          if (totalSubmissions === 0) {
            return await dispatch(getContactSubmissions(userData.uid));
          }
        }
      } catch (rejectedValueOrSerializedError) {
        // handle error here
      }
    })();
  }, [data, dispatch, ip, totalSubmissions, userData.email, userData.firstName, userData.lastName, userData.uid]);
  const validation = () => {
    var err = {};
    if (data.email?.[0].value === "") {
      err["email"] = "this field is required";
    }
    if (data.firstname?.[0].value === "") {
      err["firstname"] = "this field is required";
    }
    if (data.lastname?.[0].value === "") {
      err["lastname"] = "this field is required";
    }
    SetError({ ...err });
    return Object.values(err).length < 1 && true;
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(data);
    if (validation()) {
      setshowForm(!showForm);
      setForm(false);
      dispatch(
        addContact({ body: { val: data } })
      ).then((data) => {
        if (data.payload) {
          message.success('The changes have been saved.');
        } else {
          message.error('There was an error saving data. Please contact site administrator.');
        }
      });
    }
  };
  const handleChange = (event) => {
    SetData({ ...data, [event.target.name]: [{ value: event.target.value }] });
  };
  const handleForm = (e) => {
    setshowForm(!showForm);
    setForm(true);
  }

  return (
    <>

      {!showForm && <><h1 className="page-header">Contact</h1>
        {totalSubmissions > 0 && <>
          <div className="alert alert-success">You have already submitted this form. <a href="/submissions">View your previous submissions.</a></div>
        </>}
        <>
          <form className="form-group contact-form">
            <div
              className={classNames(
                "form-item",
                error.firstname !== "" && error.firstname && "error"
              )}
            >
              <label>
                First
                <span className="form-required" title="This field is required.">
                  *
                </span>
              </label>
              <input
                className="form-control"
                onChange={handleChange}
                defaultValue={userData.firstName}
                name="firstname"
              />
            </div>

            <div
              className={classNames(
                "form-item",
                error.lastname !== "" && error.lastname && "error"
              )}
            >
              <label>
                Last
                <span className="form-required" title="This field is required.">
                  *
                </span>
              </label>
              <input
                className="form-control"
                onChange={handleChange}
                defaultValue={userData.lastName}
                name="lastname"
              />
            </div>
            <div
              className={classNames(
                "form-item",
                error.email !== "" && error.email && "error"
              )}
            >
              <label>
                Email
                <span className="form-required" title="This field is required.">
                  *
                </span>
              </label>
              <input
                className="form-control"
                onChange={handleChange}
                defaultValue={userData.email}
                name="email"
              />
            </div>
            <label>
              Comments
              <span
                className="form-required"
                title="This field is required."
              ></span>
            </label>
            <textarea
              className="form-control"
              name="comments"
              cols="60"
              rows="5"
              onChange={handleChange}
            />
          </form>
          <button className="btn btn-danger btn-md" onClick={handleClick}>
            Submit
          </button>
        </></>}
      {
        showForm && <>
          <div className="contact-form-confirmation">
            <p>Thank you, your submission has been received.</p>
            <button onClick={handleForm}>Go back to the form</button>
          </div>

        </>
      }


    </>
  );
};

export default ContactForm;
