import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDocuCollection } from "../../store/slices/documents";
// import dayjs from "dayjs";
// import axios from "axios";
import "./default.template.less";
import { useHistory } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { addPackages } from "../../store/slices/packages";
// import { getSinglePackage } from "../../store/slices/packages";
import { NavLink } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';

const PreviewForm = (props) => {
  const data = props.location.state;
  const history = useHistory();
  const dispatch = useDispatch();
  const uid = data?.field_documents;
  const [packageData, setPackageData] = useState([]);
  const [refresh, SetRefresh] = useState(0);
  const [packageName, setPackageName] = useState([]);
  const [userLocation, setUserLocation] = useState([]);

  useEffect(() => {

    data !== null && data !== undefined && (async () => {
      const PackageList = await dispatch(getDocuCollection(parseInt(uid)));
      const title = unwrapResult(PackageList)?.[0]?.title;
      const list = unwrapResult(PackageList)?.[0]?.field_documents;
      const arr = typeof (list) == "object" && Object.values(list);
      setPackageData(arr);
      setPackageName(title);

      // Find user location by ID
      const userLoc = data?.location?.find(x => x.nid === data?.user_location).title;
      setUserLocation(userLoc);
    })();

  }, [uid, data, dispatch]);

  const handleClick = () => {
    SetRefresh(1);
    dispatch(addPackages({ val: data })).then(response => {
      if (response) {
        // Only redirect to the my packages page after success creation
        if (response.payload.status === 1) {
          history.push({ pathname: "/my-packages" });
          SetRefresh(0)
        }
      }
    });
  };

  return (
    <>
      {refresh > 0 ?
        <ThreeDots className="loader" color="#DDDDDD" height={50} width={50} />
        :
        !data ? history.push("/packages/add") : <>
          <div className="table-responsive package-preview-form">
            <table className="PreviewTable">
              <tbody>
                <tr>
                  <td className="head">Job Number</td>
                  <td>{data?.field_job_number}</td>
                </tr>
                <tr>
                  <td className="head">Expiration Date</td>
                  <td>{data?.field_expDate}</td>
                </tr>
                {(() => {
                  if (data?.field_notes !== "") {
                    return (
                      <tr>
                        <td className="head">Notes</td>
                        <td>{data?.field_notes}</td>
                      </tr>
                    )
                  }
                })()}
                <tr>
                  <td className="head">Package Details</td>
                  <td>
                    <p>The <b>{packageName}</b> Jobsite Package includes the following documents:</p>
                    {
                      packageData.length > 0 && <> <ul>
                        {packageData?.map((key, id) => {
                          return (
                            <li key={id}>
                              <NavLink to={`/document/${key.id}`}>{key.label}</NavLink>
                            </li>
                          );
                        })}
                      </ul></>
                    }
                  </td>
                </tr>
                {(() => {
                  if (data?.address_line1 !== "") {
                    return (
                      <tr>
                        <td className="head">Address</td>
                        <td>
                          {data?.address_line1}
                          <br />
                          {data?.address_line2}
                          <br />
                          {data?.locality}, {data?.administrative_area} {data?.postal_code}
                        </td>
                      </tr>
                    )
                  }
                })()}
                {(() => {
                  if (data?.field_customer_name !== "") {
                    return (
                      <tr>
                        <td className="head">Customer Name</td>
                        <td>{data?.field_customer_name}</td>
                      </tr>
                    )
                  }
                })()}
                <tr>
                  <td className="head">User Location</td>
                  <td>{userLocation}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <button
            className="btn btn-danger btn-md"
            onClick={() => history.push({ pathname: "/packages/add", state: data })}
          >
            Previous
          </button>
          <button className="btn btn-danger btn-md" onClick={handleClick}>
            Publish
          </button>
        </>}
    </>
  );
};

export default PreviewForm;
