import React from 'react'
import { useEffect } from "react";

const Select = ({ field_name, options, default_value, placeholder, method, data }) => {

  useEffect(() => {
    if (default_value) {
      method({ ...data, [field_name]: default_value, method })
    }
  }, [default_value, data, field_name, method]);

  return (
    <>
      {options &&
        <select className='form-control' defaultValue={default_value} onChange={(e) => { method({ ...data, [field_name]: e.target.value }) }}>
          { placeholder ? <option key="" value="">{placeholder}</option> : "" }
          {Object.keys(options).map((item) => (
            <option key={item} value={options[item].nid}>{options[item].title}</option>
          ))}
        </select>
      }
    </>
  );
}

export default Select
